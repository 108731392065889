import { updateCartNumber } from "./cart_helpers";
window.addEventListener("load", function (e) {
    var btnMenuSandwich = document.getElementById("menu-mobile__sandwitch");
    var sidebarMenu = document.getElementById("sidebar-menu");
    updateCartNumber();
    btnMenuSandwich.addEventListener("click", function (e) {
        e.stopPropagation();
        if (sidebarMenu.classList.contains("open"))
            sidebarMenu.classList.remove("open");
        else
            sidebarMenu.classList.add("open");
    });
    sidebarMenu.addEventListener('click', function (e) {
        e.stopPropagation();
    });
    document.body.addEventListener('click', function () {
        sidebarMenu.classList.remove("open");
    });
});
